import { filterNonEmptyKeys } from "@/helpers/filterNonEmptyKeys";
import { ref } from "vue";

interface RouteQueryParams {
  heroid?: number;
  categories?: string;
  posid?: string;
  herowhite?: string;
}

export const routeQueryParams = ref<RouteQueryParams>({
  heroid: 0,
  categories: '',
  posid: '',
  herowhite: '',
});

export const useRouteQueryParams = () => {
  const synchronizeQueryParams = () => {
    const urlObj = new URL(window.location.toString());
    const params = new URLSearchParams(urlObj.search);
    const queryParams: Record<string, string> = {};
    params.forEach((value, key) => {
      queryParams[key] = value;
    });
    routeQueryParams.value = queryParams;
  };

  const setQueryParams = (params: Partial<RouteQueryParams>) => {
    const queryParams = {
      ...routeQueryParams.value,
      ...params,
    };
    routeQueryParams.value = filterNonEmptyKeys(queryParams);
  };

  return {
    setQueryParams,
    synchronizeQueryParams,
  };
};