const sortFn = (key, asc) => (a, b) => {
  const aVal = a?.[key];
  const bVal = b?.[key];
  if (asc) {
    return aVal > bVal ? 1 : -1;
  }
  return aVal > bVal ? -1 : 1;
};

export default ({
  array,
  currentVariant,
  sideOptionsFilter,
  studiedOptionsFilter,
  watchListOptionsFilter,
  sortByOptionsFilter,
  hmRange,
  deltaRange,
  positionStatusFilter
}) => {
  // const fixString = (str) => str.replace("{", "[").replace("}", "]");
  const [min, max] = hmRange;
  const [minDelta, maxDelta] = deltaRange;
  const hmFiltered = array.filter((item) => {
    const hm = item.hm;

    if (!hmRange || hmRange.length === 0) return true;

    return hm >= 2 * (min - 1) && hm <= 2 * max;
  });

 
  const deltaFiltered = hmFiltered.filter((item) => {
   
   const delta =  Math.round(item.total_shortage / (item.total_count || 1)) / 100
    if (!deltaRange || deltaRange.length === 0) return true;

    return delta >= Number(minDelta) && delta <= Number(maxDelta);
  });

  

  const statusFiltered = deltaFiltered.filter((item) => {
      const { mdegree, studied} = item

    if(positionStatusFilter === 3) {
      return studied * mdegree > 1
    }

    if(positionStatusFilter === 1) {
      return studied === 0
    }

    if(positionStatusFilter === 2) {
      return  studied === 1
    }
   });

  const reduced = statusFiltered.reduce((acc, item) => {
    if (item.herowhite === 1 && sideOptionsFilter === 3) return acc;
    if (item.herowhite === 0 && sideOptionsFilter === 2) return acc;

    if (item.studied === 0 && studiedOptionsFilter === 2) return acc;
    if (!item.labels && watchListOptionsFilter === 2) return acc;
    if (currentVariant.id !== 1 && currentVariant.text !== item.eco_opening)
      return acc;
    acc.push(item);
    return acc;
  }, []);

  const sortWaste = (a, b) => {
    const aVal = a?.total_shortage / (a?.total_count || 1);
    const bVal = b?.total_shortage / (b?.total_count || 1);

    return aVal > bVal ? -1 : 1;
  };

  const sortByEye = (array) => {
    // const fixString = (str) => str.replace("{", "[").replace("}", "]");

    const groups = array.reduce(
      (acc, item) => {
        const labels = item.labels;
        if (!labels) {
          acc.undecided.push(item);
          return acc;
        }

        if (labels.indexOf(2) > -1) {
          acc.active.push(item);
        } else {
          acc.hidden.push(item);
        }
        return acc;
      },
      {
        active: [],
        undecided: [],
        hidden: [],
      },
    );

    const sortByMdegree = (array) => {
      const mdegreeGroups = array.reduce(
        (acc, item) => {
          const { mdegree } = item;
          if (mdegree === 0) {
            acc.green.push(item);
          }
          if (mdegree === 1) {
            acc.black.push(item);
          }
          if (mdegree === 2) {
            acc.red.push(item);
          }
          return acc;
        },
        {
          green: [],
          black: [],
          red: [],
        },
      );

      return [
        ...mdegreeGroups.red,
        ...mdegreeGroups.black,
        ...mdegreeGroups.green,
      ];
    };

    return [
      ...sortByMdegree(groups.active),
      ...sortByMdegree(groups.undecided),
      ...sortByMdegree(groups.hidden),
    ];
  };

  const sortMapper = {
    1: sortFn('total_shortage'),
    2: sortWaste,
    3: sortFn('unreasonable_count'),
    4: sortFn('hm'),
    5: sortFn('hm', true),
  };

  const presorted = reduced.sort(sortMapper[sortByOptionsFilter]);
  return sortByEye(presorted);
};
