import axios from '@/plugins/axiosWrapper';

const AUTH_URL = process.env.VUE_APP_AUTH_API;

export default {
  async registerUser({commit}, params){
    return axios({
      headers: { 'x-forwarded-for': localStorage.getItem('ip-from')  },
      method: "post",
      url: `${AUTH_URL}/api/`,
      data: {"mtd": "user_registration", ...params },
    }).catch((e) => console.log(e));
  },
  async resendConfirmation({ state }, params) {
    const token = localStorage.getItem("token");
    return axios({
      headers: { 'Authorization': `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from')  },
      method: "post",
      url: `${AUTH_URL}/api/`,
      data: {"mtd": "user_send_mail_again", ...params },
    }).catch((e) => console.log(e));
  },
  async activatePromocode({commit}, params) {
    const token = localStorage.getItem("token"); 
    return axios({
      headers: { 'Authorization': `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from')  },
      method: "post",
      url: `${AUTH_URL}/api/`,
      data: {"mtd": "user_promo_activate", ...params },
    }).catch((e) => console.log(e));
  },
};