import defaultState from './state'

export default {
    "SET_DATA_BY_KEY": (state, {key, value}) => state[key] = value,
    RESET: (state) => {
        const keys = Object.keys(state)
        for(const key of keys) {
            state[key] = defaultState[key]
        }
    },
};
