import isEqual from 'lodash/isEqual';
import store from '@/store';

function orchestrator() {
  this.dict = {};
  this.cb = () => {};

  this.findOccurance = (params) => {
    const dict = this.dict;

    for (const key of Object.keys(dict)) {
      if (isEqual(JSON.parse(key), JSON.parse(JSON.stringify(params)))) {
        return { target: dict[key], key };
      }
    }

    return null;
  };

  this.register = (params) => {
    const sig = this.findOccurance(params);
    if (sig) {
      sig.target.abort();
    }

    const controller = new AbortController();

    this.dict[JSON.stringify(params)] = controller;
    this.cb(this.dict);
    return controller;
  };

  this.kill = (params, preventCb = false) => {
    const sig = this.findOccurance(params);
    if (sig) {
      delete this.dict[sig.key];
    }
    if (!preventCb) {
      this.cb(this.dict);
    }
  };

  this.killAll = () =>
    new Promise((res) => {
      for (const sig of Object.values(this.dict)) {
        sig.abort();
      }

      this.dict = {};
      this.cb(this.dict);
      res();
    });

  this.setCb = (cb) => (this.cb = cb);
}

export default new orchestrator();
