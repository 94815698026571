<template>
  <div class="wrapper">
    <div class="label regular-14" v-if="label">{{ label }}</div>
    <div class="d-flex">
      <CustomButton
        v-for="option in options"
        :key="'so' + option.id"
        :value="option.id"
        @click="handleClick(option.id)"
        class="button"
        :label="$t(option.text)"
        :backgroundColor="modelValue === option.id ? 'rgb(74, 74, 74)' : 'rgb(30, 30, 30)'"
        textColor="white"
        customHeight="28px"
        iconClass="mr-2"
        rounded="medium"
        :disabled="isSubmitButtonDisabled"
        size="large"
        style="flex: 1; min-width: 0;"
        fontClass="regular-14"
      />
    </div>
  </div>
</template>

<script>
import CustomButton from '../LoginPage/CustomButton.vue';

export default {
  name: 'ButtonSelector',
  components: {
    CustomButton,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
    isSubmitButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    handleClick(value) {
      this.updateModel(value);
      this.$emit('update:filtersChanged'); // Emit the change event here
    },
    updateModel(value) {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: static;
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 10px 16px 10px;
  margin: 0px 0px;
}
.label {
  position: static;
  width: 124px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 12px;
  color: #9A9A9A;
}
.button {
  position: static;
  width: 115px;
  height: 28px;
  background: rgb(74, 74, 74);
  border-radius: 8px; /* Fixed typo from 'border-raius' */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px 4px 8px;
  margin: 0px 4px;
}
</style>
