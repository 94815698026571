<template>
  <v-app class="bg-darkGrey">
    <router-view></router-view>
    <portal to="app-bar-portal-language">
            <v-menu class="regular-14">
              <template v-slot:activator="{ props }">
                <div class="app-bar-language-button d-flex align-center pointer" v-bind="props">
                  <div class="ml-2 semibold-14 app-bar-language__desktop">{{ currentLocale?.name }}</div>
                  <svg
                    class="app-bar-language__desktop"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.48021 6.81331C5.57396 6.71968 5.70104 6.66709 5.83354 6.66709C5.96604 6.66709 6.09313 6.71968 6.18688 6.81331L8.00021 8.62665L9.81355 6.81331C9.85932 6.76419 9.91452 6.72479 9.97585 6.69746C10.0372 6.67013 10.1034 6.65544 10.1705 6.65425C10.2377 6.65307 10.3044 6.66542 10.3666 6.69057C10.4289 6.71571 10.4854 6.75314 10.5329 6.80062C10.5804 6.8481 10.6178 6.90466 10.643 6.96692C10.6681 7.02917 10.6805 7.09586 10.6793 7.163C10.6781 7.23013 10.6634 7.29634 10.6361 7.35767C10.6087 7.41901 10.5693 7.47421 10.5202 7.51998L8.35354 9.68665C8.25979 9.78028 8.13271 9.83287 8.00021 9.83287C7.86771 9.83287 7.74063 9.78028 7.64688 9.68665L5.48021 7.51998C5.38658 7.42623 5.33398 7.29915 5.33398 7.16665C5.33398 7.03415 5.38658 6.90706 5.48021 6.81331Z"
                      fill="#9A9A9A"
                    />
                  </svg>
                  <CustomIcon class="app-bar-language__mobile" id="world" />
                </div>
              </template>
              <v-list density="compact" class="regular-14">
                <v-list-item
                  v-for="item in localeItems"
                  :key="item.id"
                  @click="selectLanguage(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
    </portal>
  </v-app>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue';
import { useUIStore } from './shared/composables/useUIStore';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useDataStore } from '@/shared/composables/useDataStore';
import CustomIcon from '@/assets/CustomIcon.vue';
import { useRouteQueryParams } from '@/router/useRouteQueryParams';

interface LocaleItem {
  title: string;
  id: string;
  name: string;
};

const localeItems: LocaleItem[] = [
  { title: 'en', id: '1', name: 'English' },
  { title: 'ru', id: '4', name: 'Русский' },
];

const currentLocale = ref<LocaleItem>();

const store = useStore();
const userPreferences = computed(() => store.state.ui.userPreferences);
const isAuthenticated = computed(() => store.state.isAuthenticated);
const { commit } = useDataStore();
const { uiDispatch, uiCommit } = useUIStore();
const { synchronizeQueryParams } = useRouteQueryParams();
synchronizeQueryParams();

const token = localStorage.getItem('token');

  if (token) {
    store.commit('SET_DATA_BY_KEY', {
      key: 'isAuthenticated',
      value: true,
    });
  }

const { locale } = useI18n();

const selectLanguage = (item: LocaleItem): void => {
  currentLocale.value = item;
  locale.value = item.title;
  if (!isAuthenticated.value) {
      uiCommit('SET_DATA_BY_KEY', {
        key: 'userPreferences',
        value: { lang_interface: Number(item.id) },
      });
      localStorage.setItem('savedLocale', JSON.stringify(item));
    } else {
      localStorage.setItem('savedLocale', JSON.stringify(item));
      uiDispatch('setUserPreferences', {
        ...userPreferences.value,
        lang_interface: Number(item.id),
      });
    }
};

const savedLocale = localStorage.getItem('savedLocale');

if (savedLocale) {
  try {
    const locale = JSON.parse(savedLocale);
    selectLanguage(locale);
  } catch (error) {
    console.log(error);
  }
} else {
  currentLocale.value = localeItems[0];
}

watch(userPreferences, (val) => {
  if (val && val.lang_interface !== undefined) {
    const targ = localeItems.find(
      (i) => Number(i.id) === val.lang_interface,
    );
    if (targ) {
      currentLocale.value = targ;
      locale.value = targ.title;
      localStorage.setItem('savedLocale', JSON.stringify(targ));
    }
  }
},
  { immediate: true },
);

const setScreenSizes = (): void => {
  store.commit('SET_DATA_BY_KEY', {
        key: 'sizes',
        value: {
        vh:
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight,
        vw:
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth,
      },
  });
};

onMounted(() => {
  setScreenSizes();
  window.addEventListener('resize', setScreenSizes);
});
</script>

<style scoped>
@media (max-width: 959px) {
  .app-bar-language-button {
    position: absolute;
    z-index: 9000;
    right: 23px;
    top: 23px;
  }
}
@media (max-width: 767px) {
  .app-bar-language__desktop {
    display: none;
  }
}

@media (min-width: 768px) {
 .app-bar-language__mobile {
    display: none;
 }
}



</style>