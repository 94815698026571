<template>
  <div>
    <div v-if="label" class="semibold-14">
      {{ label }}
    </div>
    <div class="d-flex">
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="handleIconClick('decr')"
        class="sc-input-button"
      >
        <path
          d="M19.3327 14H8.66602"
          stroke="#9A9A9A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div style="width: 32px">
        <input
          :value="value"
          @change="e => onInput(e.target.value)"
          type="text"
          class="regular-14 sc-input"
        />
      </div>

      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="handleIconClick('incr')"
        class="sc-input-button"
      >
        <path
          d="M13.9993 8.66666V19.3333M19.3327 14L8.66602 14"
          stroke="#9A9A9A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">

interface InputProps {
  label?: string;
  step?: number;
  value: string | number;
}
const props = defineProps<InputProps>();

const emit = defineEmits(['onInput']);
const onInput = (value: string) => {
  if (Number.isNaN(Number(value))) return;
  emit('onInput', value);
};
const handleIconClick = (direction: string) => {
  const currentValue = Number(props.value) || 0;
  const newValue =
    currentValue +
    (props.step ? props.step : 1) * (direction === 'decr' ? -1 : 1);
  if (Number.isNaN(Number(newValue))) return;
  emit('onInput', newValue);
};
</script>

<style scoped>
.sc-input-button {
  user-select: none;
  cursor: pointer;
}

.sc-input {
  /* Dropdown */

  box-sizing: border-box;

  /* Auto layout */

  width: 32px;
  height: 32px;

  /* bg */
  background: #171717;
  /* form stroke */
  border: 1px solid #3c3c3c;
  border-radius: 8px;

  text-align: center;
}
</style>
