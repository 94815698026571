import { RouteNames } from "@/router/routeNames";
import { useRoute } from "vue-router";

export const useLastPage = () => {

  const route = useRoute();

  const removeLastPage = () => {
    localStorage.removeItem('lastPage');
  };

  const setLastPage = (pageName?: RouteNames) => {
    if (pageName) {
      localStorage.setItem('lastPage', pageName.toString());
      return;
    }
    if (route?.name) {
      localStorage.setItem('lastPage', route?.name.toString());
    }
  };

  const getLastPage = (): RouteNames | undefined => {
    try {
      const savedPage = localStorage.getItem('lastPage');
      return savedPage as RouteNames;
    } catch (error) {
      return undefined;
    }
  };

  return {
    setLastPage,
    getLastPage,
    removeLastPage,
  };
};