import axios from '@/plugins/axiosWrapper';


export default {
  async getSettingsAndPreferences({commit}) {
    const token = localStorage.getItem("token");

    const resp = await axios({
        headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
        //new API
        method: 'post',
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        data: { "mtd": "users/getinfo"},

      }).catch((e) => console.log(e));


      if (resp?.data) {

        const {calling_code, displayed_name,phone, ...prefs } = resp.data
      

        commit("SET_DATA_BY_KEY", {
            key: "userSettings",
            value: {calling_code,displayed_name,phone  },
          });

          commit("SET_DATA_BY_KEY", {
            key: "userPreferences",
            value: prefs,
          });
      };
      

      console.log(resp)
  },
  async getUserSettings({ commit }) {
    const token = localStorage.getItem("token");

    const resp = await axios({
        headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
        //new API
        method: 'post',
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        data: { "mtd": "users/getinfo"},

      }).catch((e) => console.log(e));

      // if (resp?.data?.data) {
      //   commit("SET_DATA_BY_KEY", {
      //       key: "userSettings",
      //       value: resp.data.data?.[0],
      //     });
      // };
       //new API
      if (resp?.data) {
        commit("SET_DATA_BY_KEY", {
            key: "userSettings",
            value: resp.data,
          });
      };

  },
  async setUserSettings({ commit, dispatch }, params) {
    const token = localStorage.getItem("token");
  },
  async getUserPreferences({ commit }) {
    const token = localStorage.getItem("token");

    const resp = await axios({
        headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
        method: 'post',
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        data: { "mtd": "users/getpreferences",},
      }).catch((e) => console.log(e));
   
      // if (resp?.data?.data) {
      //   commit("SET_DATA_BY_KEY", {
      //       key: "userPreferences",
      //       value: resp.data.data?.[0],
      //     });
      // };
      //new API
      if (resp?.data) {
        commit("SET_DATA_BY_KEY", {
            key: "userPreferences",
            value: resp.data,
          });
      };

  },
  async setUserPreferences({ commit, dispatch }, data) {
    const token = localStorage.getItem("token");
    delete data.user_id
    delete data.displayed_name
    delete data.use_short_notation

    // delete data.compare_tc
    const resp = await axios({
        headers: { Authorization: `Bearer ${token}`, 'x-forwarded-for': localStorage.getItem('ip-from') },
        method: "post",
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        data: {
          ...data,
          compare_ed: data.compare_ed,
          compare_tc: data.compare_tc,
          compare_elo:data.compare_elo,
          "mtd": "users/setpreferences"
          // compare_ed: data.compare_ed.join(','),

        }

      }).catch((e) => console.log(e));

      if (resp?.data?.message === 'OK') {
        dispatch('getSettingsAndPreferences')
      };
  },
};
