import defaultState from './state';

export default {
  SET_DATA_BY_KEY: (state, { key, value, freeze = false }) => {
    if (typeof key !== 'object') {
      if (!freeze) {
        state[key] = value;
      } else {
        state[key] = Object.freeze(value);
      }
    } else {
      if (key.length !== value.length) {
        console.log('SET_DATA_BY_KEY wrong length', key, value);
        return;
      }

      for (let i = 0; i < key.length; i++) {
        state[key[i]] = freeze ? Object.freeze(value[i]) : value[i];
      }
    }
  },
  RESET: (state) => {
    const keys = Object.keys(state);
    for (const key of keys) {
      state[key] = defaultState[key];
    }
  },
};
