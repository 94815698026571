import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { routes } from "./routes";
import { RouteNames } from "./routeNames";
import { useLastPage } from "@/shared/composables/useLastPage";

const isProdMode = process.env.VUE_APP_PROD_MODE;

const notRequireAuthPages = [
  RouteNames.CHANGE_PASSWORD,
  RouteNames.LOGIN,
  RouteNames.REGISTER,
  RouteNames.RECOVERY,
  RouteNames.WAITING_FOR_CONFIRMATION,
  RouteNames.PROMOCODE_FORM,
  RouteNames.PROMOCODE_SUCCESS,
  RouteNames.REGISTRATION_SUCCESS,
];
  
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  router.beforeEach(async (to, from, next) => {
    if (isProdMode) {
      if (store.state.isAuthenticated && to.name !== RouteNames.AFTER_LOGIN) {
        const savedPageName = useLastPage().getLastPage();
        if (savedPageName && savedPageName === to.name) {
          next();
          return;
        }

        next({ name: savedPageName ?? RouteNames.AFTER_LOGIN });
        return;
      }
    }

    if(to.name === RouteNames.ABOUT) {
      next();
      return;
    }
    if (!notRequireAuthPages.includes(to.name as RouteNames) && !store.state.isAuthenticated) {
      if (isProdMode) {
        next({ name: RouteNames.REGISTER });
        return;
      }
      next({ name: RouteNames.LOGIN });
      return;
    }
    if (notRequireAuthPages.includes(to.name as RouteNames) && store.state.isAuthenticated) {
      next({ name: RouteNames.CARDS });
      return;
    }
    next();
  });

  export default router;